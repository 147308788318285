// Composables
import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/store/user';
import { useAuthenticationStore } from '@/store/common/authentication';

import Login from '@/views/Auth/Login.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import SelectAgent from '@/views/Auth/SelectAgent.vue';
import Catalogue from '@/views/Catalogue.vue';
import ApproveUser from '@/views/Auth/ApproveUser.vue';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            requiresAuth: false,
            allowedRoles: []
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
            requiresAuth: false,
            allowedRoles: []
        }
    },
    {
        path: '/set-password',
        name: 'set-password',
        component: ResetPassword,
        meta: {
            requiresAuth: false,
            allowedRoles: []
        }
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword,
        meta: {
            requiresAuth: false,
            allowedRoles: []
        }
    },
    {
        path: '/approve-user',
        name: 'approve-user',
        component: ApproveUser,
        meta: {
            requiresAuth: false,
            allowedRoles: []
        }
    },
    {
        path: '/select-agent',
        name: 'select-agent',
        component: SelectAgent,
        meta: {
            requiresAuth: true,
            allowedRoles: ['ROLE_SUPERAGENT']
        }
    },
    {
        path: '/cms',
        component: () => import('@/layouts/cms/CMS.vue'),
        children: [
            {
                path: 'texts',
                name: 'cms-texts',
                component: () => import('@/views/CMS/Texts.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_CMS_USER']
                }
            },
            {
                path: 'banner',
                name: 'cms-banner',
                component: () => import('@/views/CMS/Banner.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_CMS_USER']
                }
            },
            {
                path: 'products',
                name: 'cms-products',
                component: () => import('@/views/CMS/Products.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_CMS_USER']
                }
            },
            {
                path: 'preview',
                name: 'cms-preview',
                component: () => import('@/views/CMS/Preview.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_CMS_USER']
                }
            },
            {
                path: 'groups',
                name: 'cms-groups',
                component: () => import('@/views/CMS/Groups.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_CMS_USER']
                }
            }
        ]
    },
    {
        path: '/',
        component: () => import('@/layouts/fullscreen/Fullscreen.vue'),
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('@/views/Home.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                }
            }
        ]
    },
    {
        path: '/',
        component: () => import('@/layouts/default/Default.vue'),
        children: [
            {
                path: '403',
                name: 'error-403',
                component: import('@/views/Error/Error403.vue'),
                props: { mode: 'app' },
                meta: {
                    requiresAuth: false,
                    allowedRoles: []
                }
            },
            {
                path: '*',
                component: import('@/views/Error/Error404.vue'),
                meta: {
                    requiresAuth: false,
                    allowedRoles: []
                }
            },
            {
                path: 'catalogue/:seasonId',
                name: 'catalogue',
                component: Catalogue,
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                }
            },
            {
                path: 'pdp/:productId',
                name: 'pdp',
                component: () => import('@/views/PDP.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                }
            },
            {
                path: 'profile',
                name: 'profile',
                component: () => import('@/views/Profile.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                }
            },
            {
                path: '/cart/order',
                component: () => import('@/views/Cart/orderComplete/MainCartCompleteOrder.vue'),
                children: [
                    {
                        path: ':customerId?',
                        name: 'cart-complete-order',
                        component: () => import('@/views/Cart/orderComplete/Customers.vue'),
                        meta: {
                            requiresAuth: true,
                            allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                        }
                    },
                    {
                        path: 'new-customer',
                        name: 'cart-complete-order-new-customer',
                        component: () => import('@/views/ClientNew.vue'),
                        meta: {
                            requiresAuth: true,
                            allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT']
                        }
                    }
                ]
            },
            {
                path: 'cart',
                name: 'cart',
                component: () => import('@/views/Cart.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                }
            },
            {
                path: 'clients',
                name: 'clients',
                component: () => import('@/views/Clients.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT']
                }
            },
            {
                path: 'clients/new',
                name: 'client-new',
                component: () => import('@/views/ClientNew.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT']
                }
            },
            {
                path: 'client/:id',
                name: 'client',
                component: () => import('@/views/Client.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT']
                }
            },
            {
                path: 'my-orders',
                name: 'my-orders',
                component: () => import('@/views/MyOrders.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                }
            },
            {
                path: 'search',
                name: 'search',
                component: () => import('@/views/Search.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                }
            },
            {
                path: 'selections/:id',
                name: 'selection',
                component: () => import('@/views/Selection.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                }
            },
            {
                path: 'selections',
                name: 'selections',
                component: () => import('@/views/Selections.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                }
            },
            {
                path: 'orders-and-documents',
                name: 'orders-and-documents',
                component: () => import('@/views/OrdersAndDocuments/OrdersAndDocuments.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                },
                children: [
                    {
                        path: 'order',
                        name: 'order',
                        component: () => import('@/views/OrdersAndDocuments/Orders.vue'),
                        meta: {
                            requiresAuth: true,
                            allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                        }
                    },
                    {
                        path: 'delivery-note',
                        name: 'deliveryNote',
                        component: () => import('@/views/OrdersAndDocuments/DeliveryNotes.vue'),
                        meta: {
                            requiresAuth: true,
                            allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                        }
                    },
                    {
                        path: 'invoice',
                        name: 'invoice',
                        component: () => import('@/views/OrdersAndDocuments/Invoices.vue'),
                        meta: {
                            requiresAuth: true,
                            allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                        }
                    },
                    {
                        path: 'refund',
                        name: 'refund',
                        component: () => import('@/views/OrdersAndDocuments/Refunds.vue'),
                        meta: {
                            requiresAuth: true,
                            allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                        }
                    }
                ]
            },
            {
                path: 'communication-material',
                name: 'communication-material',
                component: () => import('@/views/CommunicationMaterial/CommunicationMaterial.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                }
            },
            {
                path: 'orders/details/:id',
                name: 'orders-details',
                component: () => import('@/views/OrdersAndDocuments/OrdersDetails.vue'),
                meta: {
                    requiresAuth: true,
                    allowedRoles: ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT', 'ROLE_CUSTOMER']
                }
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),

    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});

/**
 * Check the error
 */
router.onError((error, to) => {
    if (
        error.message.includes('Failed to fetch dynamically imported module') ||
        error.message.includes('Importing a module script failed')
    ) {
        if (!to?.fullPath) {
            window.location.reload();
        } else {
            window.location = to.fullPath;
        }
    }
});

/**
 * Before each route, check if the user is logged in and if the route requires authentication
 * If the user is not logged in and the route requires authentication, redirect to the login page
 * If the user is logged in and the route is the login page, redirect to the home page
 *
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 *
 * @returns {Promise}
 */
router.beforeEach((to, from, next) => {
    const userStore = useUserStore();
    const authenticationStore = useAuthenticationStore();

    const hasAValidToken = authenticationStore.hasAValidToken();
    const requiresAuth = to.meta.requiresAuth;

    userStore.loadLocales();

    if (hasAValidToken) {
        userStore.loadInfo();
    }

    const isAuthenticatedAndUserLoaded = hasAValidToken ? userStore.isLogged : null;
    if (!isAuthenticatedAndUserLoaded && requiresAuth) {
        // If the user is not logged in and the route requires authentication, redirect to the login page
        return next({ name: 'login' });
    }

    if (isAuthenticatedAndUserLoaded) {
        authenticationStore.scheduleTimerForUpdateToken();

        if (to.name === 'login' || (!userStore.isCMS && to.name?.includes('cms'))) {
            // If the user is logged in and the route is the login page
            // or route is CMS and user isn't CMS, redirect to the home page
            return next({ name: 'home' });
        } else if (userStore.isSuperAgent && to.name !== 'select-agent' && !userStore.agentSelected) {
            // If the user is logged in and is a super agent, redirect to the select agent page
            return next({ name: 'select-agent' });
        } else if (
            to.meta.allowedRoles?.length &&
            !to.meta.allowedRoles.some(allowedRole => userStore.getUserRoles.includes(allowedRole))
        ) {
            // If the user is logged in and the route requires a role that the user doesn't have, redirect to the home page
            return next({ name: 'home' });
        }
    }

    return next();
});

export default router;
