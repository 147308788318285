<template>
    <div class="product-wrapper">
        <div
            class="pa-4 pb-3 product cursor-pointer"
            elevation="0"
        >
            <v-img
                :src="props.product.image || DEFAULT_IMAGE"
                :alt="props.product.name"
                aspect-ratio="1"
                class="mb-4 product__image"
            >
                <div class="product__image__favorite px-3 py-3">
                    <FavoriteIcon
                        v-if="mode === 'color'"
                        :selections="productSelectionIds"
                        @update:favorite="updateFavorite"
                    />
                </div>
            </v-img>

            <h5 class="text-h5 mb-2">
                {{ props.product.name }}
            </h5>

            <span class="body-medium d-block text-grey-scale-2 mb-2">
                {{ props.product.reference }}

                <span
                    v-if="mode === 'color'"
                    class="ml-2"
                    v-text="props.product?.colors?.[0]?.name || ''"
                />
            </span>

            <span class="body-medium d-block text-grey-scale-2 mb-2">
                <template v-if="props.product.sizes[0] === props.product.sizes[1]">
                    {{ $t('product.sizes') }} {{ props.product.sizes[0] }}
                </template>
                <template v-else>
                    {{ $t('product.sizes', 2) }} {{ props.product.sizes[0] }} - {{ props.product.sizes[1] }}
                </template>
            </span>

            <span class="product__price d-block mb-4">
                <!-- Discount if oldPrice exists -->
                <span
                    v-if="props.product.oldPrice"
                    class="mr-1"
                >
                    <span class="product__price--old">{{ $n(props.product.oldPrice, userCurrency) }}</span>
                </span>
                <!-- Unique price for each product -->
                <span v-if="props.product.prices[0] === props.product.prices[1]">
                    {{ $n(props.product.prices[1], userCurrency) }}
                </span>
                <!-- Price range -->
                <span v-else>
                    {{ $t('product.from') }} {{ $n(props.product.prices[0], userCurrency) }} {{ $t('product.to') }}
                    {{ $n(props.product.prices[1], userCurrency) }}
                </span>
            </span>

            <div class="d-flex flex-wrap align-center">
                <div
                    v-for="color in props.product.colors"
                    :key="color.id"
                    :style="{ backgroundColor: color.rgb }"
                    :class="`product__color mr-2 mb-2 ${color.name}`"
                    :title="color.name"
                ></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import { useUserStore } from '@/store/user';

import FavoriteIcon from '@/components/Selections/FavoriteIcon.vue';

const props = defineProps({
    product: {
        type: Object,
        required: true
    },
    mode: {
        type: String,
        default: 'model'
    }
});

// Emits
const emit = defineEmits(['update:favorite-product']);

// Store
const userStore = useUserStore();
const { getCurrency: userCurrency } = storeToRefs(userStore);

// Selections
const { productSelectionIds, updateFavorite } = useProductSelections();

/**
 * Reusable functions specific to this component
 */
function useProductSelections() {
    const productSelectionIds = computed(() => {
        const [color] = props.product.colors;

        return color?.selections ?? [];
    });

    const updateFavorite = ({ selectionId, isSelected }) => {
        emit('update:favorite-product', { selectionId, isSelected });
    };

    return { productSelectionIds, updateFavorite };
}

const DEFAULT_IMAGE = '/images/selection/selection-default.svg';
</script>

<style lang="scss" scoped>
.product-wrapper {
    height: 100%;
}
.product {
    background: $white;
    height: 100%;
    &__image {
        position: relative;

        &__favorite {
            position: absolute;
            top: 0;
            right: 0.6rem;
        }
    }

    &__price {
        font-size: 0.875rem;
        font-weight: 650;
        letter-spacing: 0.063;
        &--old {
            text-decoration: line-through;
            color: $grey-scale-4;
        }
    }

    &__color {
        width: 31px;
        height: 31px;
        border-radius: 100%;
        border: 1px solid var(--white);
        &.BLANCO,
        &.WHITE {
            border: 1px solid var(--grey-scale-3);
        }
    }
}
</style>
