import { toRaw } from 'vue';
import { defineStore } from 'pinia';
import { useUserStore } from '@/store/user';
import ProductsApiService from '@/services/ProductsApiServices';

const PAGINATION_LIMIT = 10;
const PAGINATION_OFFSET = 0;
const UNIQUE_SIZE = 'UNICA';

function getDefaultState() {
    return {
        products: [],
        mode: 'color', // model, color
        filters: {},
        search: '',
        filtersSelected: {
            type: [],
            section: [],
            category: [],
            color: [],
            size: [],
            sole: [],
            withStock: 0
        },
        delayedFiltersSelected: {},
        pagination: {
            limit: PAGINATION_LIMIT,
            offset: PAGINATION_OFFSET,
            total: null
        },
        orderBy: {
            field: 'name', // name, price
            type: 'ASC' // ASC, DESC
        }
    };
}

export const useProductsStore = defineStore('products', {
    state: getDefaultState,

    getters: {
        /**
         * Get the products by mode
         *
         * @param {*} state
         * @returns {Array}
         */
        getProductsByMode(state) {
            // Get the products by model
            if (state.mode === 'model') {
                return state.products.map(product => {
                    // Get stock by model
                    let productStock = 0;
                    let uniqueSize = false;

                    const productRaw = toRaw(product);
                    const productValue = productRaw.length ? productRaw[0] : productRaw;

                    // Get the sizes ranges
                    const sizeValues = productValue.colors.flatMap(color =>
                        color.sizes.map(size => {
                            productStock += Number(size.stock);

                            if (size.label === UNIQUE_SIZE) {
                                uniqueSize = true;
                            }

                            return size.label;
                        })
                    );

                    let sizeRange = [UNIQUE_SIZE, UNIQUE_SIZE];
                    if (!uniqueSize) {
                        sizeRange = [sizeValues[0], sizeValues[sizeValues.length - 1]];
                    }

                    // Get the prices ranges
                    const priceValues = productValue.colors.flatMap(color =>
                        color.sizes.map(size => Number(size.price))
                    );
                    const priceRange = [Math.min(...priceValues), Math.max(...priceValues)];

                    // Get minimum oldPrice
                    const oldPriceValues = productValue.colors.flatMap(color =>
                        color.sizes.map(size => Number(size.oldPrice))
                    );
                    const oldPriceMin = Math.min(...oldPriceValues);

                    return {
                        ...productValue,
                        sizes: sizeRange,
                        prices: priceRange,
                        oldPrice: oldPriceMin ? oldPriceMin : null,
                        image: productValue.colors[0].imageUrls[0],
                        stock: productStock
                    };
                });
            }

            // Get the products by color
            const products = state.products.flatMap(product => {
                const productValue = product.length ? product[0] : product;

                const productColors = productValue.colors.map(color => {
                    // Get stock by color
                    let productStock = 0;
                    let uniqueSize = false;

                    // Get the sizes ranges
                    const sizeValues = color.sizes.map(size => {
                        productStock += Number(size.stock);

                        if (size.label === UNIQUE_SIZE) {
                            uniqueSize = true;
                        }

                        return size.label;
                    });

                    let sizeRange = [UNIQUE_SIZE, UNIQUE_SIZE];
                    if (!uniqueSize) {
                        sizeRange = [sizeValues[0], sizeValues[sizeValues.length - 1]];
                    }

                    // Get the prices ranges
                    const priceValues = color.sizes.map(size => Number(size.price));

                    let priceRange;
                    if (priceValues.length > 0) {
                        priceRange = [Math.min(...priceValues), Math.max(...priceValues)];
                    } else {
                        priceRange = [0, 0];
                    }

                    if (color?.selections) {
                        if (!(color.selections instanceof Array)) {
                            color.selections = Object.keys(color.selections) ?? [];
                        }
                    } else {
                        color.selections = [];
                    }

                    // Get minimum oldPrice
                    const oldPriceValues = color.sizes.map(size => Number(size.oldPrice));
                    const oldPriceMin = oldPriceValues.length > 0 ? Math.min(...oldPriceValues) : null;

                    return {
                        ...productValue,
                        colors: [color],
                        sizes: sizeRange,
                        prices: priceRange,
                        oldPrice: oldPriceMin ? oldPriceMin : null,
                        image: color.imageUrls[0],
                        stock: productStock
                    };
                });

                return productColors;
            });

            const colorsSelected = this.filtersSelected?.color;
            if (colorsSelected?.length) {
                return products.filter(p => colorsSelected.includes(p.colors?.[0]?.id));
            }

            return products;
        },

        getMode: state => state.mode
    },

    actions: {
        /**
         * Reset state
         *
         * @param {Object} state
         */
        resetState() {
            const newState = getDefaultState();
            Object.keys(newState).forEach(key => {
                this.$state[key] = newState[key];
            });
        },

        /**
         * Get the section name
         *
         * @param {Number} id
         * @returns {String}
         */
        getSectionName(id) {
            return this.filters.section.find(section => section.id === id)?.name;
        },

        /**
         * Get the sole name
         *
         * @param {Number} id
         * @returns {String}
         */
        getSoleName(id) {
            return this.filters.soles.find(sole => sole.id === id)?.name;
        },

        /**
         * Get the type name
         *
         * @param {Number} id
         * @returns {String}
         */
        getTypeName(id) {
            return this.filters.type.find(type => type.id === id)?.name;
        },

        /**
         * Get the category name
         *
         * @param {Number} id
         * @returns {String}
         */
        getCategoryName(id) {
            return this.filters.category.find(category => category.id === id)?.name;
        },

        /**
         * Get the size name
         *
         * @param {Number} value
         * @returns {String}
         */
        getSizeName(value) {
            return this.filters.size.find(size => size.value === value)?.label;
        },

        /**
         * Get the color name
         *
         * @param {Number} id
         * @returns {String}
         */
        getColorName(id) {
            if (!this.filters.color.find(color => color.id === id)?.name) {
                return undefined;
            }

            return `${id} - ${this.filters.color.find(color => color.id === id)?.name}`;
        },

        /**
         * Remove filter selected
         *
         * @param {String} name
         * @param {String} value
         *
         */
        removeFilterSelected(name, value) {
            if (name === 'withStock') {
                this.filtersSelected.withStock = 0;
            } else {
                this.filtersSelected[name] = this.filtersSelected[name].filter(filter => filter !== value);
            }
        },

        /**
         * Clear filters selected
         *
         */
        clearFiltersSelected() {
            this.filtersSelected.type = [];
            this.filtersSelected.section = [];
            this.filtersSelected.category = [];
            this.filtersSelected.color = [];
            this.filtersSelected.size = [];
            this.filtersSelected.sole = [];
            this.filtersSelected.withStock = 0;
            this.search = '';
        },

        incrementPagination() {
            this.pagination.offset += this.pagination.limit;
        },

        /**
         * Reset pagination data
         *
         */
        resetPagination() {
            this.resetProducts();
            this.pagination = {
                limit: PAGINATION_LIMIT,
                offset: PAGINATION_OFFSET,
                total: null
            };
        },

        resetProducts() {
            this.products = [];
        },

        /**
         * Set filter search
         *
         * @param {String} search
         *
         */
        setSearch(search) {
            this.search = search;
        },

        /**
         * Set Order By
         *
         * @param {Object} orderBy
         */
        setOrderBy(orderBy) {
            this.resetPagination();
            this.orderBy = orderBy;
        },

        /**
         * Reset Order By
         *
         */
        resetOrderBy() {
            this.orderBy = {
                field: 'name', // name, price
                type: 'ASC' // ASC, DESC
            };
        },

        /**
         * Load products
         * @param {Number} season
         *
         * @returns {Promise}
         */
        loadProducts(season) {
            const userStore = useUserStore();
            const priceGroupId = userStore.groupPrice;
            const payload = {
                limit: this.pagination.limit,
                offset: this.pagination.offset,
                filters: {
                    ...this.filtersSelected,
                    season
                },
                search: this.search?.length >= 3 ? this.search : null, //TODO check if necessary add null (not well validated search in the app)
                orderBy: this.orderBy
            };

            // Clear the products
            if (this.pagination.offset === 0) {
                this.products = [];
            }

            return new Promise((resolve, reject) => {
                ProductsApiService.getProducts(priceGroupId, payload)
                    .then(response => {
                        this.products.push(...response.data.products);
                        this.pagination = response.data.pagination;
                        this.filters = response.data.filters;
                        this.incrementPagination();
                        resolve(response.data.pagination);
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * Set products
         * @param {Array} products
         *
         */
        setProducts({ products, pagination }) {
            this.products = products;
            this.pagination = pagination;
            this.filters = {};
        },

        /**
         * Update favorite product locally
         * @param {Array} products
         *
         */
        updateFavoriteProductLocally({ productReference, colorId, selectionId, isSelected }) {
            this.products.flatMap(productItem => {
                const currentProduct = productItem.length ? productItem[0] : productItem;

                const foundProductColor = currentProduct.colors.find(currentColor => {
                    return currentProduct.reference === productReference && currentColor?.id === colorId;
                });

                if (foundProductColor) {
                    if (isSelected) {
                        foundProductColor.selections.push(selectionId);
                    } else {
                        foundProductColor.selections = foundProductColor.selections.filter(
                            currentId => currentId != selectionId
                        );
                    }
                }
            });
        },

        /**
         * Set mode products
         *
         * @param {String} mode
         *
         * @returns {void}
         */
        toggleMode(mode) {
            this.mode = mode;
        },

        /**
         * Add filter object to filtersSelected
         *
         * @param {String} name
         * @param {Object} filter
         *
         * @returns {void}
         */
        addFilterSelected(name, filter) {
            this.filtersSelected[name] = filter;
        },

        /**
         * Add filter object to delayedFiltersSelected
         *
         * @param {String} name
         * @param {Object} filter
         *
         * @returns {void}
         */
        addDelayedFilterSelected(name, filter) {
            this.delayedFiltersSelected[name] = filter;
        },

        /**
         * @returns {void}
         */

        copyFiltersToDelayedFilters() {
            Object.assign(this.delayedFiltersSelected, this.filtersSelected);
        },

        /**
         * @returns {void}
         */

        copyDelayedFiltersToFilters() {
            Object.assign(this.filtersSelected, this.delayedFiltersSelected);
        }
    }
});
