import i18n from '@/plugins/lang/i18n';

const { t } = i18n.global;

export default {
    required: value => !!value || t('rules.required'),

    minLength4: v => (v && v.length >= 4) || t('rules.minCharacter'),

    minLength8: v => (v && v.length >= 8) || t('rules.minCharacter'),

    maxLength30: v => !v || v.length <= 30 || t('rules.maxCharacter', { max: 30 }),

    maxLength50: v => !v || v?.length <= 50 || t('rules.maxCharacter', { max: 50 }),

    maxLength80: v => !v || v.length <= 80 || t('rules.maxCharacter', { max: 80 }),

    maxLength100: v => !v || v.length <= 100 || t('rules.maxCharacter', { max: 100 }),

    validName: function (name) {
        var re = /^[A-Z0-9 ÑÇ´ÁÉÍÓÚ',&;:;_\-.]+$/;

        return re.test(name) || t('rules.nameFormat');
    },

    validEmail: function (email) {
        var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return !email || re.test(email?.trim()) || t('rules.emailFormat');
    },

    validPhoneOrEmpty: phone => !phone || /^(\+|\d)[0-9]{7,16}$/.test(phone) || t('rules.phoneNumber'),

    // validPassword: pass => (pass && pass.length >= 4) || t('rules.password'),
    validPassword: function (pass) {
        // Generar regexp para que la contraseña tenga al menos 8 dígitos, 1 minúscula, 1 mayúscula y algunos de estos caracteres especiales: .-_!?
        const regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[.\-_!?]).{8,}$/;

        return regexp.test(pass) || t('rules.password');
    },

    validNumber: number => /^(\+|\d)[0-9]{0,}$/.test(number) || t('rules.number'),

    validNumberOrEmpty: number => !number || /^(\+|\d)[0-9]{0,}$/.test(number) || t('rules.number'),

    requiredAtLeastOne: v => v.length !== 0 || t('rules.required'),

    validWeb: web =>
        !web ||
        /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/.test(
            web
        ) ||
        t('rules.web'),

    validDecimalNumber: number => /^[+-]?\d+(\.\d+)?$/.test(number) || t('rules.decimalNumber'),

    // TODO falta definir
    validZipCode: zipCode => {
        const checkZipCodeSize = zipCode && zipCode.length >= 4 && zipCode.length <= 30;
        const re = /^[A-ZÑ0-9 ,._\-/ ()]+$/;

        return (checkZipCodeSize && re.test(zipCode)) || t('rules.zipCode');
    },

    // TODO falta definir
    validNif: v => (v && v.length >= 5) || t('rules.nif'),

    // TODO falta definir
    validIban: v => (v && v.length >= 5) || t('rules.iban')
};
