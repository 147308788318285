<template>
    <h3
        class="text-center mb-12 mt-8"
        v-html="$t('auth.firstAccess.title')"
    ></h3>

    <v-row class="d-flex justify-between">
        <v-col
            cols="6"
            class="d-flex justify-center"
        >
            <Button
                class="w-100"
                :text="$t('common.no')"
                bordered
                data-testid="first-access-no"
                @click="emit('alert-confirm', false)"
            />
        </v-col>
        <v-col
            cols="6"
            class="d-flex justify-center"
        >
            <Button
                class="w-100"
                :text="$t('common.yes')"
                data-testid="first-access-yes"
                @click="emit('alert-confirm', true)"
            />
        </v-col>
    </v-row>
</template>

<script setup>
import Button from '@/components/Common/Button.vue';

const emit = defineEmits(['alert-confirm']);
</script>
