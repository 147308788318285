{
  "common": {
    "code": "Code",
    "title": "Titre",
    "description": "Description",
    "yes": "Oui",
    "no": "Non",
    "ok": "Ok",
    "back": "Retour",
    "percent": "%",
    "comment": "Commentaire",
    "date": "Date",
    "expiration": "Expiration",
    "data": "Donnée",
    "status": "Statut",
    "information": "Informations",
    "see": "Voir",
    "colors": "Couleurs",
    "total": "Total",
    "erp": "ERP",
    "b2b": "B2B",
    "and": "Et",
    "form": {
      "placeHolder": {
        "date": "Selectionnez une date",
        "comment": "Écrivez ici votre commentaire",
        "email": "Écrivez ici les emails séparés par des virgules",
        "orderName": "Écrivez ici le nom de la commande"
      },
      "noDataText": {
        "select": "Aucun élément",
        "filterNoMatch": "Auncun résultat ne coincide avec la recherche"
      }
    },
    "noDataText": {
      "select": "Sélectionner"
    },
    "action": {
      "send": "Envoyer",
      "save": "Sauvegarder",
      "edit": "Modifier",
      "delete": "Supprimer",
      "search": "Rechercher",
      "close": "Fermer",
      "cancel": "Annuler",
      "apply": "Appliquer",
      "continue": "Continuer",
      "confirm": "Confirmer",
      "copyToClipboard": "Copier dans le presse-papiers"
    },
    "dialog": {
      "confirmation": "Confirmation",
      "information": "Information",
      "supportInfo": "Si vous avez besoin d'aide, vous pouvez envoyer un e-mail à {email}"
    },
    "notification": {
      "create": {
        "success": "Correctement créé",
        "error": "Des problèmes sont survenus en créant l’élément"
      },
      "update": {
        "success": "Correctement mis à jour",
        "error": "Des problèmes sont survenus lors de la mise à jour"
      },
      "delete": {
        "success": "Correctement Eliminé",
        "error": "Des problèmes sont survenus lors de l'élimination de l'élément"
      },
      "read": {
        "error": "Des problèmes sont survenus lors chargement de certaines données"
      },
      "send": {
        "success": "Opération correctement réalisée",
        "error": "Des problèmes sont survenus en réalisant l'action"
      }
    },
    "statusOptions": {
      "confirmed": "Confirmé",
      "pending": "En attente de confirmation",
      "awaiting_confirmation": "En attente de confirmation",
      "awaiting_cofirmation": "En attente de confirmation",
      "cancelled": "Annulé",
      "canceled": "Annulé",
      "new": "Nouveau",
      "accepted": "Accepté"
    }
  },
  "global": {
    "welcome": {
      "title": "Bienvenue au nouveau<br>B2B de Victoria!",
      "message": "Si c'est la première fois que vous accédez, vous devez vous inscrire pour pouvoir accéder.",
      "contact": "*Pour toute question, nous sommes à votre disposition à <a href=\"mailto:{email}\">{email}</a>"
    },
    "selectLocale": "Selectionnez une langue",
    "menuLeft": {
      "myOrders": "Mes commandes",
      "selections": "Sélections",
      "favorites": "Favoris",
      "communicationMaterial": "Support de communication",
      "ordersAndDocuments": "Commandes et documents",
      "clients": "Clients"
    },
    "menuTop": {
      "myData": "Mes données",
      "changeAgent": "Changer d'agent",
      "goToCMS": "Aller au CMS",
      "logout": "Fermer la session"
    }
  },
  "auth": {
    "username": "Nom d'utilisateur",
    "password": "Mot de passe",
    "repeatPassword": "Répéter le mot de passe",
    "login": "Se connecter",
    "register": "Registre",
    "email": "Entrez votre email d'inscription ici",
    "forgotPassword": "Vous avez oublié votre mot de passe ?",
    "passwordMatch": "Le mot de passe doit correspondre",
    "newPassword": "Écrivez ici votre nouveau mot de passe",
    "repeatNewPassword": "Répétez le nouveau mot de passe",
    "welcome": "Bienvenue !",
    "selectAgent": "Choisissez le profil que vous souhaitez consulter",
    "changePassword": "Changer le mot de passe",
    "currentPassword": "Mot de passe actuel",
    "registerMessage": "Nous vérifions votre demande, vous recevrez un mail confirmant la création de votre nouveau compte.",
    "forgotPasswordMessage": "Un lien a été envoyé à {email}, avec des informations sur la manière de réinitialiser votre mot de passe.",
    "invalidLink": "Le lien n'est pas valide ou a expiré",
    "approveUser": "Voulez-vous approuver l'utilisateur ?",
    "yourSessionIsExpired": "Votre session a expiré",
    "pleaseReInsertPassword": "Veuillez réinsérer votre mot de passe",
    "ifNotCurrentUserYouCan": "Si {user} n'est pas votre utilisateur, vous pouvez ",
    "changeUser": "changer d'utilisateur",
    "errors": {
      "register": "Pour activer votre compte, contactez-nous au {email} (code: {code})",
      "invalidLink": "Le lien n'est pas valide ou a expiré (code: {code})",
      "login": "Des problèmes d'authentification se sont produits sur le serveur",
      "codeInformation": "Des problèmes d'authentification se sont produits sur le serveur (code: {code})"
    },
    "success": {
      "approveUser": "L'utilisateur a été approuvé avec succès",
      "passwordUpdated": "Le mot de passe a été mis à jour avec succès"
    },
    "firstAccess": {
      "title": "Merci de visiter le <span class=\"text-red\">NOUVEAU</span> B2B de Victoria<br>Pour accéder, une NOUVELLE inscription obligatoire est requise.<br>Est-ce la première fois que vous nous rendez visite ?"
    }
  },
  "user": {
    "info": {
      "myUser": "Mon utilisateur",
      "nif": "NIF",
      "name": "Nom",
      "customerName": "Nom du client",
      "phone": "Téléphone",
      "cifNif": "CIF/NIF",
      "commercialReason": "Nom commercial",
      "contactPerson": "Personne à contacter",
      "email": "email | emails",
      "clientGroup": "Groupe de clients",
      "template": "Modèle de déclaration fiscale",
      "subagent": "Sous-agent",
      "agent": "Agent",
      "userCode": "Code utilisateur",
      "clientCode": "Code client",
      "helpText": "Si vous souhaitez modifier vos données, veuillez envoyer un mail à {email}."
    },
    "placeHolder": {
      "customer": {
        "name": "Saisissez ici le nom du nouveau client",
        "phone": "Saisissez ici le numéro de téléphone du nouveau client",
        "businessName": "Écrivez ici le nom de l'entreprise",
        "commercialReason": "Saisir le nom commercial ici",
        "contactPerson": "Saisir le nom de la personne de contact",
        "email": "Saisissez l'email",
        "group": "Sélectionnez un groupe de clients",
        "template": "Sélectionnez un modèle de déclaration fiscale",
        "subagent": "Sélectionnez un sous-agent"
      }
    },
    "unsubscribe": "Se désabonner",
    "dialog": {
      "unsubscribe": "Êtes-vous sûr de vouloir vous désinscrire ?"
    }
  },
  "cms": {
    "cmsEditContent": "Modifier le contenu",
    "newHome": "Créer une nouvelle Home",
    "setName": "Nommer",
    "writeName": "Rédiger le nom de la nouvelle Home",
    "selectedBackground": "Fond sélectionné",
    "loadedOptions": "Options chargées",
    "importContent": "Importer du contenu",
    "acceptedImages": "Formats acceptés : JPG, PNG, MP4 et GIF.\n            Taille maximale recommandée : 100 Mo.\n            Dimensions recommandées 1760 x 574px.",
    "fileOverLimit": "Le fichier dépasse la taille maximale",
    "fileInHome": "Le fichier ne peut pas être supprimé car il est utilisé dans {homes}.",
    "confirmDelete": "Êtes-vous sûr de vouloir supprimer le contenu de la bannière ?",
    "addProducts": "Ajouter des produits",
    "selectProducts": "Sélectionner des produits",
    "selectedProducts": "Produits sélectionnés",
    "searchByCode": "Rechercher par code",
    "name": "Nom",
    "homes": "Pages d'accueil",
    "products": "Produits",
    "selectBannerMedia": "Sélectionnez une image / une vidéo pour la bannière",
    "admin": "Administrateur CMS",
    "menu": {
      "text": "Texte de la bannière",
      "content": "Contenu de la bannière",
      "products": "Produits",
      "preview": "Aperçu",
      "priceGroups": "Groupes de prix",
      "b2b": "Aller à B2B"
    },
    "tabs": {
      "notice": "Avertissement",
      "extraInfo": "Informations supplémentaires",
      "copy": "Copie"
    },
    "fields": {
      "phone": "Téléphone",
      "description": "Description",
      "title": "Titre",
      "showInfo": "Afficher les informations"
    }
  },
  "product": {
    "sizes": "Taille | Tailles",
    "from": "Depuis",
    "to": "Jusqu'à",
    "sizeCurve": "Courbe de taille",
    "info": "Info",
    "composition": "Composition",
    "pvp": "P.V.P",
    "pc": "PC",
    "legendGreen": "+ 20 paires en stock",
    "legendYellow": "- 20 paires en stock",
    "legendRed": "Pas de stock actuellement",
    "legendBlack": "Non disponible",
    "units": "unités",
    "season": "Saison",
    "sku": "SKU",
    "prices": "Prix",
    "quantities": "Quantité | Quantités",
    "serie": "Série"
  },
  "address": {
    "name": "Nom",
    "street": "Adresse",
    "city": "Ville",
    "city2": "Ville",
    "region": "Région",
    "zip": "Code postal",
    "country": "Pays",
    "addTitle": "Ajouter une nouvelle adresse",
    "editTitle": "Modifier l'adresse",
    "areAddressesEquals": "L'adresse de facturation et l'adresse de livraison sont-elles les mêmes ?",
    "addressName": "Nom de l'adresse",
    "placeHolder": {
      "default": {
        "select": "Sélectionnez une adresse de livraison"
      },
      "customer": {
        "nif": "Saisissez ici le NIF de {item}",
        "name": "Indiquez ici le nom de votre nouvelle adresse",
        "street": "Indiquez ici l'adresse de l'{item}",
        "city": "Indiquez ici la ville de {item}",
        "region": "Indiquez ici la région de {item}",
        "zip": "Indiquez ici le code postal de {item}",
        "country": "Sélectionnez un pays"
      },
      "shipping": {
        "name": "Saisissez le nom ici",
        "street": "Saisissez ici l'adresse de livraison ",
        "city": "Saisissez ici la ville",
        "region": "Saisissez ici la région",
        "zip": "Saisissez ici le code postal"
      }
    }
  },
  "order": {
    "billingAddress": "Adresse de facturation",
    "client": "Client",
    "contactData": "Coordonnées",
    "returnToOrder": "Retourner à la commande",
    "origin": "Origine",
    "quantityOrdered": "Quantité commandée",
    "quantityPending": "Quantité en attente",
    "quantitySent": "Quantité envoyée",
    "complete": "Complet",
    "pending": "En attente",
    "detailTitle": "Information de la commande",
    "reference": "Référence de la commande",
    "articles": "Article | Articles",
    "pendingQuantities": "Quantité en attente",
    "repeat": "Commander de nouveau",
    "pairTotal": "Quantité totale",
    "requestedDate": "Date de livraison demandée",
    "payment": {
      "method": "Mode de paiement",
      "form": "Mode de paiement",
      "bank": "Banque",
      "bankName": "Nom de la banque",
      "iban": "IBAN",
      "terms": "Conditions de paiement",
      "observations": "Observations",
      "clientObservations": "Observations du client",
      "allowsOrders": "Autorise les commandes",
      "bankCode": "Code banque",
      "bankInfo": "Si vous avez besoin de modifier ou d'ajouter une banque, veuillez envoyer un courriel à {email}.",
      "placeHolder": {
        "form": "Selectionnez un mode de paiement",
        "bankName": "Saisissez ici le nom de la banque",
        "iban": "Saisissez ici l'IBAN",
        "terms": "Sélectionnez les modalités de paiement"
      }
    },
    "shipping": {
      "address": "Adresse de livraison | Adresses de livraison",
      "addressInfo": "Si vous avez besoin d'ajouter ou de modifier une adresse, envoyez-nous un mail à {email}.",
      "editAddress": "Modifier l'adresse de livraison",
      "addAddress": "Ajouter une nouvelle adresse de livraison"
    },
    "price": {
      "shippingCost": "Frais de livraison",
      "transportCost": "Frais de transport {prix}",
      "freeShippingFrom": "Livraison gratuite à partir de {prix}",
      "amount": "Montant",
      "grossAmount": "Montant brut",
      "taxes": "Taxes",
      "type": "Type",
      "base": "Base",
      "amountTax": "Montant de la taxe",
      "discounts": "Remises",
      "surcharges": "Surtaxes",
      "totalSum": "Montant total",
      "subtotal": "Sous-total",
      "subtotalWithDiscounts": "Sous-total avec remises",
      "taxAmount": "Montant de la taxe",
      "paymentTermsDiscount": "Remise de modalités de paiement",
      "customerDiscount": "Remise client",
      "shipping": "Livraison",
      "surchargeAmount": "Montant de la surtaxe"
    },
    "customer": {
      "label": "Client",
      "new": "Nouveau client",
      "existing": "Client existant",
      "select": "Sélectionnez le client",
      "businessName": "Raison sociale",
      "create": {
        "success": "Votre nouveau client a été enregistré avec succès. | Votre nouveau client a été enregistré avec succès. Vous pouvez maintenant terminer votre commande",
        "error": "Des problèmes sont revenus lors de l'enregistrement du client"
      }
    },
    "notifications": {
      "success": "Votre commande a été passée avec succès. Vous recevrez sous peu un mail contenant toutes les informations."
    }
  },
  "cart": {
    "title": "Panier",
    "addToCart": "Ajouter au panier",
    "doOrder": "Passer commande",
    "totalProduct": "Total produit",
    "totalProducts": "Total produit",
    "quantity": "unités",
    "emptyMessage": "Votre panier est vide, veuillez ajouter des produits pour pouvoir passer la prochaine commande.",
    "goToCart": "Accéder au panier",
    "added": {
      "addedProducts": "Les produits ont été ajoutés au panier",
      "addedQuantities": "Les unités ont été ajoutées au panier.",
      "addedQuantity": "1 article a été ajouté à votre panier. | Vous avez ajouté {count} articles à votre panier.",
      "noAddedQuantityNoAvailable": "1 modèle n'a pas pu être ajouté car il n'est pas disponible pour le moment. | Un modèle n'a pas pu être ajouté car il n'est pas disponible pour le moment."
    },
    "order": {
      "completeTitle": "Compléter les détails de la commande",
      "requestedIssueDate": "Date d'expédition sollicitée",
      "make": "Passer commande",
      "yourOrderReference": "Votre référence de commande"
    }
  },
  "filter": {
    "filters": "Filtres",
    "search": "Recherche",
    "searchIn": "Rechercher dans",
    "nameASC": "Nom de A à Z",
    "nameDESC": "Nom de Z à A",
    "priceASC": "Prix du plus bas au plus élevé",
    "priceDESC": "Prix du plus élevé au plus bas",
    "skuASC": "Référence de moins à plus",
    "skuDESC": "Référence de plus à moins",
    "type": "Type",
    "sole": "Semelle",
    "section": "Section",
    "size": "Taille",
    "availability": "Disponibilité",
    "availableStock": "Stock disponible",
    "color": "Couleur",
    "category": "Catégorie",
    "availabilityQuote": "Afficher uniquement les produits en stock",
    "boy": "Garcon",
    "girl": "Fille",
    "children": "Enfants",
    "searchMinLength": "La recherche doit contenir au moins {min} caractères",
    "withStock": "Avec stock"
  },
  "selections": {
    "title": "Sélections",
    "advice": "Pour créer une nouvelle sélection, choisissez le modèle couleur avec le cœur et donnez un nom à votre sélection. Ensuite, vous pourrez l'envoyer par e-mail et/ou la convertir en commande.",
    "btnNew": "Nouvelle sélection",
    "addNameTitle": "Proposer une nouvelle sélection",
    "renameTitle": "Renommer “{name}”",
    "placeHolderName": "Nom de la sélection",
    "btnMoveToCart": "Déplacer la sélection vers le panier",
    "returnToItem": "Retour à la sélection",
    "dialog": {
      "deleteMessage": "Êtes-vous sûr de vouloir supprimer cette sélection ? Êtes-vous sûr de vouloir supprimer ces {count} sélections ?",
      "deleteProducts": "Êtes-vous sûr de vouvoir supprimer ce produit de la sélection ? Êtes-vous sûr de vouvoir supprimer ces {count} produits-couleurs de la sélection ?",
      "moveToCartMessage": "Êtes-vous sûr de vouloir ajouter cette sélection à votre panier ?",
      "cartAlreadyExists": "Attention, le panier contient déjà des produits.",
      "saveTo": "Sauvegarder dans les sélections",
      "sendByEmail": "Envoyer sélection par email"
    },
    "notification": {
      "create": {
        "success": "Sélection correctement créée",
        "error": "Problèmes survenus lors de création de la sélection"
      },
      "addProduct": {
        "success": "Le produit a été correctement enregistré dans \"{nom}\".",
        "error": "Problèmes survenus lors de l'enregistrement de la sélection des produits"
      },
      "removeProduct": {
        "success": "Correctement supprimé de la sélection",
        "error": "Problèmes survenus lors de la désélection du produit"
      },
      "emailSent": {
        "success": "Email envoyé avec succès",
        "error": "Problèmes survenus lors de l'envoi de l'email"
      }
    }
  },
  "rules": {
    "required": "Requis",
    "nameFormat": "Nom invalide",
    "emailFormat": "Email invalide",
    "phoneNumber": "Numéro de téléphone invalide",
    "password": "Le mot de passe doit contenir au moins 8 caractères, une lettre majuscule, une lettre minuscule et un de ces symboles: .-_ !?",
    "passwordConfirmation": "La confirmation du mot de passe ne correspond pas",
    "number": "Numéro invalide",
    "web": "Site web invalide",
    "decimalNumber": "Nombre décimal invalide",
    "minCharacter": "Minimum 8 caractères",
    "emailTaken": "Le mail a déjà été pris",
    "passwordNewDifferent": "Le nouveau mot de passe doit être différent du mot de passe actuel.",
    "usernameTaken": "Le nom d'utilisateur est déjà pris",
    "username": "Le nom d'utilisateur est invalide",
    "zipCode": "Le code postal est invalide",
    "nif": "Le NIF est invalide",
    "iban": "L'IBAN est invalide",
    "maxCharacter": "Maximum {max} caractères"
  },
  "table": {
    "of": "of",
    "rowCount": "Lignes par page"
  },
  "documents": {
    "order": "Commande | Commandes",
    "invoice": "Facture | Factures",
    "deliveryNote": "Bon de livraison | Bons de livraison",
    "refund": "Remboursement",
    "ordersAndDocuments": "Commandes et documents",
    "zipCode": "Le code postal est invalide",
    "nif": "Le NIF est invalide",
    "iban": "L'IBAN est invalide",
    "pdf": "PDF",
    "availablePDF": "PDF disponible",
    "completePDF": "PDF complet",
    "pendingPDF": "Paires PDF en attente",
    "requestPDF": "Demander PDF",
    "requestedPDF": "PDF demandé",
    "orderActions": "Actions demandées",
    "package": "Vrac ",
    "notification": {
      "request": {
        "success": "La demande de PDF a été envoyée avec succès. Cette opération prendra quelques minutes.",
        "error": "Des problèmes sont survenus lors de l'envoi de la demande de PDF"
      },
      "download": {
        "success": "Le PDF a été téléchargé avec succès",
        "error": "Des problèmes sont survenus lors du téléchargement du PDF"
      }
    }
  },
  "$vuetify": {
    "input": {
      "clear": ""
    }
  },
  "icon": {
    "title": {
      "trash": "Supprimer",
      "resizeToggled": "Plier",
      "resize": "Déplier",
      "clearQuantity": "Effacer les quantités",
      "copyChecked": "Copier",
      "copy": "Copier",
      "paste": "Coller",
      "search": "Rechercher",
      "cart": "Panier",
      "cartMarked": "Panier",
      "heart": "Ajouter aux favoris",
      "info": "Information"
    }
  }
}
